import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  TextField,
  Container,
  IconButton,
} from '@mui/material';
// components
import moment from 'moment';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';

// sections
import ListToolbar from '../../segments/@dashboard/common/ListToolbar';
import ListHead from '../../segments/@dashboard/common/ListHead';
import DeleteConfirmDialog from '../../segments/@dashboard/common/DeleteConfirm';
import CreateHolidayDialog from '../../segments/@dashboard/holiday/CreateHolidayDialog';
import { deleteHoliday, getAllHoliday } from '../../server/api/holiday';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  // { id: '_id', label: 'Sl no', alignRight: false },
  { id: 'create_on', label: 'Created On', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: '', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------
HolidayPage.propTypes = {
  subjectId: PropTypes.string,
};
export default function HolidayPage({ subjectId }) {
  const dispatch = useDispatch();

  const { loading, holidaysList } = useSelector((state) => ({
    loading: state.holiday.loading,
    holidaysList: state.holiday.holidaysList,
  }));

  const [batchType, setHolidayType] = useState({
    tab: 0,
    label: 'holiday',
  });

  const [sortDate, setSortDate] = useState({
    from: '',
    to: '',
  });

  const [openHolidayId, setOpenHolidayId] = useState({});

  const [openCreateHolidayDialog, setOpenCreateHolidayDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [sortDirection, setSortDirection] = useState('asc'); // asc , desc
  const [sortBy, setSortBy] = useState('');

  const [selectedHolidayIds, setSelectedHolidayIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const total = holidaysList?.total;

  useEffect(() => {
    const requestData = {
      page: page + 1,
      limit,
      search: searchText,
      sortBy,
      sortDirection,
      dispatch,
      subjectId,
      sortDate,
    };
    dispatch(getAllHoliday(requestData));
  }, [dispatch, page, limit, searchText, sortBy, sortDirection, subjectId, sortDate]);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = holidaysList?.list?.map((n) => n._id);
      setSelectedHolidayIds(newSelectedIds);
      return;
    }
    setSelectedHolidayIds([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setSearchText(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * limit - total) : 0;

  const handleDeleteClick = (item) => {
    setOpenHolidayId(item);
    setOpenDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      holidayId: openHolidayId?._id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteHoliday(credentials));
  };

  const handleEditClick = (item) => {
    if (item) {
      setOpenHolidayId(item);
    }
    setOpenCreateHolidayDialog(true);
  };

  return (
    <>
      <MetaHelmet title="Holidays" />
      {openCreateHolidayDialog && (
        <CreateHolidayDialog
          setOpen={setOpenCreateHolidayDialog}
          open={openCreateHolidayDialog}
          isUpdate={openHolidayId}
          subjectId={subjectId}
          setOpenHolidayId={setOpenHolidayId}
        />
      )}

      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        item={[openHolidayId.title]}
        title="Holiday"
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          {subjectId ? '' : 'Holidays'}
        </Typography>
        <Button
          onClick={() => setOpenCreateHolidayDialog(true)}
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          New Holiday
        </Button>
      </Stack>
      <Container>
        <Card sx={{ padding: 3 }}>
          <Stack direction={'row'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <ListToolbar
              numSelected={selectedHolidayIds.length}
              filterName={searchText}
              onFilterName={handleFilterByName}
              setType={setHolidayType}
              type={batchType}
              // handleDelete={}
              heading={{ title: 'Holidays list', subTitle: `Total ${holidaysList?.total} Holiday` }}
              children={''}
            />
            <Stack direction={'row'} alignItems={'center'} sx={{ marginRight: 5 }}>
              <TextField
                name="date"
                label="From Date"
                sx={{ width: '100%', mr: 2 }}
                size="small"
                type="date"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setSortDate({
                    ...sortDate,
                    from: e.target.value,
                  })
                }
              />
              <TextField
                name="date"
                label="To Date"
                sx={{ width: '100%' }}
                size="small"
                type="date"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setSortDate({
                    ...sortDate,
                    to: e.target.value,
                  })
                }
              />
            </Stack>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="warning" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {holidaysList?.list <= 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No holiday yet!
                          </Typography>

                          <Typography variant="body2">
                            No results found &nbsp;
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <ListHead
                      order={sortDirection}
                      orderBy={sortBy}
                      headLabel={TABLE_HEAD}
                      rowCount={total}
                      onRequestSort={handleRequestSort}
                      numSelected={selectedHolidayIds.length}
                      onSelectAllClick={handleSelectAllClick}
                      // isCheckBox
                    />
                    <TableBody>
                      {holidaysList?.list?.map((item, key) => {
                        const { _id, title, description, date, createdAt } = item;
                        const selectedHoliday = selectedHolidayIds.indexOf(_id) !== -1;
                        return (
                          <TableRow hover key={key} tabIndex={-1} role="checkbox" selected={selectedHoliday}>
                            {/* <TableCell padding="checkbox">
                            <Checkbox checked={selectedHoliday} onChange={(event) => handleClick(event, _id)} />
                          </TableCell> */}
                            {/* 
                            <TableCell align="left">
                              <Typography
                                variant="overline"
                                noWrap
                                sx={{ cursor: 'pointer' }}
                                onClick={() => handleEditClick(item)}
                              >
                                {key + 1}
                              </Typography>
                            </TableCell> */}
                            <TableCell align="left">{moment(createdAt).format('ll')}</TableCell>

                            <TableCell align="left">{title}</TableCell>
                            <TableCell align="left">{description}</TableCell>
                            <TableCell align="left">{moment(date).format('ll')}</TableCell>

                            <TableCell align="right">
                              <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
                                <IconButton size="large" color="inherit" onClick={() => handleEditClick(item)}>
                                  <Iconify icon={'eva:edit-fill'} />
                                </IconButton>
                                <IconButton size="large" color="error" onClick={() => handleDeleteClick(item)}>
                                  <Iconify icon={'eva:trash-2-outline'} />
                                </IconButton>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {total > 0 && (
            <TablePagination
              rowsPerPageOptions={[1, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>
    </>
  );
}
