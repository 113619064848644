import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

// @mui
import {
  Stack,
  Alert,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  Box,
  MenuItem,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// components
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { colors } from '../../../theme/colors';
import { createSession, deleteSession, updateSessionDetails } from '../../../server/api/sessions';
import { daysData } from '../../../pages/Session/SessionTable';

dayjs.extend(utc);
dayjs.extend(timezone);

CreateSessionDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.any,
  day: PropTypes.any,
};

export default function CreateSessionDialog({ open, setOpen, isUpdate, day }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => ({
    loading: state.sessions.loading,
    error: state.sessions.error,
  }));

  const [selectedDay, setSelectedDay] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      timeFrom: '',
      timeTo: '',
      title: '',
    },
  });

  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: { ...formData, day: selectedDay || day },
      dispatch,
      handleClose,
    };
    if (isUpdate) {
      const updateCredentials = {
        ...credentials,
        sessionId: isUpdate?._id,
      };
      dispatch(updateSessionDetails(updateCredentials));
    } else {
      dispatch(createSession(credentials));
    }
  };

  useEffect(() => {
    const uaeTime = dayjs().tz('Asia/Dubai');

    if (isUpdate) {
      reset({
        timeFrom: isUpdate?.timeFrom,
        timeTo: isUpdate?.timeTo,
        title: isUpdate?.title,
      });
      setSelectedDay(isUpdate?.day);
    } else {
      reset({
        timeFrom: uaeTime.format('HH:mm'),
        timeTo: uaeTime.add(1, 'hour').format('HH:mm'),
        title: '',
      });
      setSelectedDay(day);
    }
  }, [day, isUpdate, reset]);

  // useEffect(() => {
  //   const currentDateNormalDate = new Date();

  //   const uaeTime = currentDateNormalDate.toLocaleString('en-US', {
  //     timeZone: 'Asia/Dubai',
  //   });

  //   if (isUpdate) {
  //     reset({
  //       timeFrom: isUpdate?.timeFrom,
  //       timeTo: isUpdate?.timeTo,
  //       title: isUpdate?.title,
  //     });
  //     setSelectedDay(isUpdate?.day);
  //   } else {
  //     reset({
  //       timeFrom: uaeTime.format('HH:mm'),
  //       timeTo: uaeTime.add(1, 'hour').format('HH:mm'),
  //       title: '',
  //     });
  //   }
  // }, [isUpdate, reset]);

  const handleDeleteSession = () => {
    const updateCredentials = {
      sessionId: isUpdate?._id,
      dispatch,
      handleCloseDeleteDialog: handleClose,
    };
    dispatch(deleteSession(updateCredentials));
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{isUpdate ? 'Update Session' : 'Create Session'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Stack direction="column" spacing={2}>
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>

              {isUpdate && (
                <Stack spacing={2} direction={'row'}>
                  <TextField
                    id="outlined-select-currency"
                    size="small"
                    required
                    select
                    label="Select"
                    defaultValue={isUpdate?.day}
                    onChange={(e) => setSelectedDay(e.target.value)}
                    name="day"
                    sx={{ width: '100%', mb: 2, textTransform: 'capitalize' }}
                  >
                    {daysData.map((option) => (
                      <MenuItem key={option} value={option} sx={{ textTransform: 'capitalize' }}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
              )}
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="timeFrom"
                  label="Time From"
                  {...register('timeFrom', {
                    required: { value: true, message: 'Time From is required' },
                  })}
                  error={!!errors.timeFrom}
                  helperText={errors?.timeFrom?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                  type="time"
                  InputLabelProps={{ shrink: true }}
                  required
                />

                <TextField
                  required
                  name="timeTo"
                  label="Time To"
                  {...register('timeTo', {
                    required: { value: true, message: 'Time To is required' },
                  })}
                  error={!!errors.timeTo}
                  helperText={errors?.timeTo?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                  type="time"
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="title"
                  label="Title"
                  {...register('title', {
                    required: { value: false },
                  })}
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                  type="text"
                />
              </Stack>
              <DialogActions
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  {isUpdate && (
                    <Tooltip title="remove session">
                      <IconButton
                        sx={{
                          mr: 1,
                        }}
                        color="error"
                        onClick={handleDeleteSession}
                      >
                        <DeleteOutlineIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
                <Box>
                  <Button autoFocus onClick={handleClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    sx={{
                      backgroundColor: colors.green,
                      color: 'white',
                      padding: '5px 20px',
                      ml: 2,
                    }}
                    size="small"
                    type="submit"
                    variant="contained"
                    color="info"
                    loading={loading}
                    autoFocus
                  >
                    {isUpdate ? 'Update' : 'Create'}
                  </LoadingButton>
                </Box>
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
