import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  TextField,
  Autocomplete,
  Container,
  IconButton,
} from '@mui/material';
// components
import moment from 'moment';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';

// sections
import ListToolbar from '../../segments/@dashboard/common/ListToolbar';
import ListHead from '../../segments/@dashboard/common/ListHead';
import DeleteConfirmDialog from '../../segments/@dashboard/common/DeleteConfirm';
import CreateLeaveDialog from '../../segments/@dashboard/leave/CreateLeaveDialog';
import { deleteLeave, getAllLeave } from '../../server/api/leave';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  // { id: '_id', label: 'Sl no', alignRight: false },
  { id: 'created_on', label: 'Created On', alignRight: false },
  { id: 'staff', label: 'Staff', alignRight: false },
  { id: 'reason', label: 'Leave Reason', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: '', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------
LeavePage.propTypes = {
  subjectId: PropTypes.string,
};
export default function LeavePage({ subjectId }) {
  const dispatch = useDispatch();

  const { loading, leavesList, staffLoading, staffsList } = useSelector((state) => ({
    loading: state.leave.loading,
    leavesList: state.leave.leavesList,
    staffLoading: state.staffs.loading,
    staffsList: state.staffs.staffList,
  }));

  const [batchType, setLeaveType] = useState({
    tab: 0,
    label: 'leaves',
  });
  const [sortDate, setSortDate] = useState({
    from: '',
    to: '',
  });

  const [openLeaveId, setOpenLeaveId] = useState({});

  const [openCreateLeaveDialog, setOpenCreateLeaveDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [sortDirection, setSortDirection] = useState('asc'); // asc , desc
  const [sortBy, setSortBy] = useState('');

  const [selectedLeaveIds, setSelectedLeaveIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const total = leavesList?.total;

  const [selectedStaffId, setSelectedStaffId] = useState('');
  const [selectedStaff, setSelectedStaff] = useState('');

  useEffect(() => {
    const requestData = {
      page: page + 1,
      limit,
      search: searchText,
      sortBy,
      sortDirection,
      dispatch,
      subjectId,
      sortDate,
      staffId: '',
    };

    if (selectedStaffId && selectedStaffId !== null) {
      requestData.staffId = selectedStaffId;
    }

    dispatch(getAllLeave(requestData));
  }, [dispatch, page, limit, searchText, sortBy, sortDirection, subjectId, sortDate, selectedStaffId]);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = leavesList?.list?.map((n) => n._id);
      setSelectedLeaveIds(newSelectedIds);
      return;
    }
    setSelectedLeaveIds([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setSearchText(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * limit - total) : 0;

  const handleDeleteClick = (item) => {
    setOpenLeaveId(item);
    setOpenDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      leaveId: openLeaveId?._id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteLeave(credentials));
  };

  const handleEditClick = (item) => {
    if (item) {
      setOpenLeaveId(item);
    }
    setOpenCreateLeaveDialog(true);
  };

  return (
    <>
      <MetaHelmet title="Leaves" />
      {openCreateLeaveDialog && (
        <CreateLeaveDialog
          setOpen={setOpenCreateLeaveDialog}
          open={openCreateLeaveDialog}
          isUpdate={openLeaveId}
          subjectId={subjectId}
          setOpenLeaveId={setOpenLeaveId}
        />
      )}
      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        item={[openLeaveId.reason]}
        title="Leave"
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          {subjectId ? '' : 'Leaves'}
        </Typography>
        <Button
          onClick={() => setOpenCreateLeaveDialog(true)}
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          New Leave
        </Button>
      </Stack>

      <Container>
        <Card sx={{ padding: 3 }}>
          <Stack direction={'row'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <ListToolbar
              numSelected={selectedLeaveIds.length}
              filterName={searchText}
              onFilterName={handleFilterByName}
              setType={setLeaveType}
              type={batchType}
              // handleDelete={}
              heading={{ title: 'Leaves list', subTitle: `Total ${leavesList?.total} Leaves` }}
              sx={{ ml: 'start' }} // This will align the ListToolbar to the start
              children={''}
            />
            <Stack direction={'row'} alignItems={'center'} display={'flex'}>
              <Stack width={'100%'} spacing={2} direction={'row'}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  loading={staffLoading}
                  options={(staffsList?.list || [])?.map((item) => ({
                    id: item?._id,
                    label: item?.name,
                  }))}
                  sx={{ minWidth: 200, mr: 2 }}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSelectedStaffId(newValue?.id);
                      setSelectedStaff(newValue?.label);
                    } else {
                      setSelectedStaffId(null);
                      setSelectedStaff(null);
                    }
                  }}
                  value={selectedStaff}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select a staff"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Stack>
              <TextField
                name="date"
                label="From Date"
                sx={{ width: '100%', mr: 2 }}
                size="small"
                type="date"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setSortDate({
                    ...sortDate,
                    from: e.target.value,
                  })
                }
              />
              <TextField
                name="date"
                label="To Date"
                sx={{ width: '100%' }}
                size="small"
                type="date"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setSortDate({
                    ...sortDate,
                    to: e.target.value,
                  })
                }
              />
            </Stack>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="warning" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {leavesList?.list <= 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No leaves yet!
                          </Typography>

                          <Typography variant="body2">
                            No results found &nbsp;
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <ListHead
                      order={sortDirection}
                      orderBy={sortBy}
                      headLabel={TABLE_HEAD}
                      rowCount={total}
                      onRequestSort={handleRequestSort}
                      numSelected={selectedLeaveIds.length}
                      onSelectAllClick={handleSelectAllClick}
                      // isCheckBox
                    />
                    <TableBody>
                      {leavesList?.list?.map((item) => {
                        const { _id, staff, reason, date, createdAt } = item;
                        const selectedLeave = selectedLeaveIds.indexOf(_id) !== -1;
                        return (
                          <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedLeave}>
                            {/* <TableCell padding="checkbox">
                            <Checkbox checked={selectedLeave} onChange={(event) => handleClick(event, _id)} />
                          </TableCell> */}

                            {/* <TableCell align="left">
                              <Typography
                                variant="overline"
                                noWrap
                                sx={{ cursor: 'pointer' }}
                                onClick={() => handleEditClick(item)}
                              >
                                {key + 1}
                              </Typography>
                            </TableCell> */}

                            <TableCell align="left">{moment(createdAt).format('ll')}</TableCell>

                            <TableCell align="left">{staff?.name}</TableCell>
                            <TableCell align="left">{reason}</TableCell>
                            <TableCell align="left">{moment(date).format('ll')}</TableCell>

                            <TableCell align="right">
                              <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
                                <IconButton size="large" color="inherit" onClick={() => handleEditClick(item)}>
                                  <Iconify icon={'eva:edit-fill'} />
                                </IconButton>
                                <IconButton size="large" color="error" onClick={() => handleDeleteClick(item)}>
                                  <Iconify icon={'eva:trash-2-outline'} />
                                </IconButton>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {total > 0 && (
            <TablePagination
              rowsPerPageOptions={[1, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>
    </>
  );
}
