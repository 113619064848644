import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Grid,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';

// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';
// import { deleteStaff, getAllStaff } from '../../server/api/users';

// sections
import ListToolbar from '../../segments/@dashboard/common/ListToolbar';
import ListHead from '../../segments/@dashboard/common/ListHead';
import DeleteConfirmDialog from '../../segments/@dashboard/common/DeleteConfirm';
import CreateStaffsDialog from '../../segments/@dashboard/staffs/StaffsCreateDialog';
import { deleteStaff, getAllStaff } from '../../server/api/staff';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  // { _id: 'auto_id', label: 'Sl no', alignRight: false },
  { _id: 'created_on', label: 'Created On', alignRight: false },
  { _id: 'name', label: 'Name', alignRight: false },
  { _id: 'designation', label: 'Designation', alignRight: false },
  { _id: 'phone', label: 'Phone', alignRight: false },
  { _id: 'email', label: 'Email', alignRight: false },
  { _id: '', label: 'Action', alignRight: true },
];

export default function StaffPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, staffsList } = useSelector((state) => ({
    loading: state.staffs.loading,
    staffsList: state.staffs.staffList,
  }));

  const [staffType, setStaffType] = useState({
    tab: 0,
    label: 'all',
  });

  const [open, setOpen] = useState(null);
  const [openStaffId, setOpenStaffId] = useState({
    _id: '',
    name: '',
  });

  const [openCreateStaffDialog, setOpenCreateStaffDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [sortDirection, setSortDirection] = useState('asc'); // asc , desc
  const [sortBy, setSortBy] = useState('');

  const [selectedStaffIds, setSelectedStaffIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const total = staffsList?.total;

  useEffect(() => {
    const credentials = {
      page: page + 1,
      limit,
      search: searchText,
      sortBy,
      sortDirection,
      dispatch,
    };
    dispatch(getAllStaff(credentials));
  }, [dispatch, page, limit, searchText, sortBy, sortDirection]);

  // const handleOpenMenu = (event, _id, name) => {
  //   setOpenStaffId({
  //     _id,
  //     name,
  //   });
  //   setOpen(event.currentTarget);
  // };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = staffsList?.list?.map((n) => n._id);
      setSelectedStaffIds(newSelectedIds);
      return;
    }
    setSelectedStaffIds([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    const text = event.target.value;
    debouncedSetSearchText(text);
  };

  const debouncedSetSearchText = debounce((text) => {
    setSearchText(text);
  }, 600);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * limit - total) : 0;

  const handleDeleteClick = (_id, name) => {
    setOpenStaffId({
      _id,
      name,
    });
    handleCloseMenu();
    setOpenDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      staffId: openStaffId?._id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteStaff(credentials));
  };

  const handleEditClick = (_id) => {
    if (_id && _id !== null) {
      navigate(`/dashboard/staff/${_id}`, {
        state: _id,
      });
    } else {
      navigate(`/dashboard/staff/${openStaffId?.name}`, {
        state: openStaffId?._id,
      });
      handleCloseMenu();
    }
  };

  return (
    <>
      <MetaHelmet title="Staffs" />
      {openCreateStaffDialog && <CreateStaffsDialog setOpen={setOpenCreateStaffDialog} open={openCreateStaffDialog} />}

      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        item={[openStaffId.name]}
        title="Staff"
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Staffs
        </Typography>
        <Button
          onClick={() => setOpenCreateStaffDialog(true)}
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          New Staff
        </Button>
      </Stack>

      <Card sx={{ padding: 3 }}>
        <Grid alignItems={3}>
          <Stack sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
            <ListToolbar
              numSelected={selectedStaffIds.length}
              filterName={searchText}
              onFilterName={handleFilterByName}
              setType={setStaffType}
              type={staffType}
              // handleDelete={}
              // tabs={[]}
              heading={{ title: 'Staffs list', subTitle: `Total ${staffsList?.total} Staffs` }}
            />
          </Stack>
        </Grid>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              {loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <CircularProgress color="warning" />
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              {staffsList?.list <= 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          No staffs yet!
                        </Typography>

                        <Typography variant="body2">
                          No results found &nbsp;
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  <ListHead
                    order={sortDirection}
                    orderBy={sortBy}
                    headLabel={TABLE_HEAD}
                    rowCount={total}
                    onRequestSort={handleRequestSort}
                    numSelected={selectedStaffIds.length}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {staffsList?.list?.map((item) => {
                      const { _id, name, phone, email, designation, createdAt } = item;
                      const selectedStaff = selectedStaffIds.indexOf(_id) !== -1;
                      return (
                        <TableRow
                          hover
                          key={`${_id}-staff-table`}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedStaff}
                        >
                          {/* <TableCell padding="checkbox">
                              <Checkbox checked={selectedStaff} onChange={(event) => handleClick(event, _id)} />
                            </TableCell> */}
                          {/* 
                          <TableCell align="left">
                            <Typography
                              variant="overline"
                              noWrap
                              sx={{ cursor: 'pointer' }}
                              onClick={() => handleEditClick(item?._id)}
                            >
                              {key + 1}
                            </Typography>
                          </TableCell> */}
                          <TableCell align="left">{moment(createdAt).format('ll')}</TableCell>

                          <TableCell align="left">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              sx={{ cursor: 'pointer' }}
                              onClick={() => handleEditClick(_id)}
                            >
                              {name}
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{designation}</TableCell>
                          <TableCell align="left">{phone}</TableCell>
                          <TableCell align="left">{email}</TableCell>

                          <TableCell align="right">
                            {/* <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, _id, name)}>
                              <MoreVertIcon />
                            </IconButton> */}
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
                              <IconButton size="large" color="inherit" onClick={() => handleEditClick(_id)}>
                                <VisibilityIcon titleAccess="View Deatils" />
                              </IconButton>
                              <IconButton
                                size="large"
                                color="error"
                                onClick={() => handleDeleteClick(item?._id, item?.name)}
                              >
                                <Iconify icon={'eva:trash-2-outline'} />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        {total > 0 && (
          <TablePagination
            rowsPerPageOptions={[1, 5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Card>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleEditClick(null)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem onClick={handleDeleteClick} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
