import React, { useState } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Typography, Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import CreateSessionDialog from '../../segments/@dashboard/session/SessionCreateDialog';
import SessionCard from './SessionCard';

export const daysData = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const SessionTable = ({ items }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 1100,
    },
    heading: {
      marginBottom: theme.spacing(1),
    },
    card: {
      marginBottom: theme.spacing(2),
      width: 165,
    },
  }));
  const classes = useStyles();

  const [openCreateSessionDialog, setOpenCreateSessionDialog] = useState(false);
  const [openSessionId, setOpenSessionId] = useState('');
  const [currentSelectedDay, setCurrentSelectedDay] = useState('');

  const handleOpenPopup = (item, day) => {
    console.log(day);
    console.log(item);

    setCurrentSelectedDay(day);
    setOpenCreateSessionDialog(true);
    if (item) {
      setOpenSessionId(item);
    } else {
      setOpenSessionId('');
    }
  };

  return (
    <>
      <CreateSessionDialog
        setOpen={setOpenCreateSessionDialog}
        open={openCreateSessionDialog}
        isUpdate={openSessionId || null}
        day={currentSelectedDay}
      />

      <Table className="session-table">
        <TableHead>
          <TableRow>
            {daysData.map((day) => (
              <TableCell key={day}>
                <Stack
                  direction={'column'}
                  alignItems={'start'}
                  sx={{
                    minWidth: '120px',
                  }}
                >
                  <Typography
                    sx={{
                      px: 1.5,
                    }}
                    variant="overline"
                  >
                    {day}
                  </Typography>
                  <Button
                    onClick={() => handleOpenPopup(false, day)}
                    width="fit-content"
                    variant="text"
                    color="success"
                    sx={{
                      px: 1,
                      fontSize: '12px',
                    }}
                  >
                    <AddIcon fontSize="small" /> Add Session
                  </Button>
                </Stack>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className="table-pd">
          <TableRow className="table-pd2">
            {daysData.map((day) => (
              <TableCell key={day} className="session-card-pd">
                {items
                  ?.filter((task) => task.day === day)
                  ?.map((task, index) => (
                    <div key={index}>
                      <SessionCard classes={classes} day={day} handleOpenPopup={handleOpenPopup} task={task} />
                    </div>
                  ))}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default SessionTable;

SessionTable.propTypes = {
  items: PropTypes.any,
};

// function newFunction(classes, handleOpenPopup, task) {
//   const timeFrom = useFormatTime(task.timeFrom)
//   return <Card className={classes.card}>
//     <CardActionArea onClick={() => handleOpenPopup(task)}>
//       <CardContent>
//         <Typography variant="body2" component="p">
//           {task.title || 'title'}
//         </Typography>
//         <Typography color={colors.green} variant="body2" component="p" fontWeight={600}>
//           {timeFrom} - {task.timeTo}
//         </Typography>
//         <Typography variant="caption" component="p">
//           session Added in <br /> {moment(task.createdAt).format('ll')}
//         </Typography>
//       </CardContent>
//     </CardActionArea>
//   </Card>;
// }
